import React from "react";
import "./Features.css";

const Features = () => {
  return (
    <section id="features" className="features">
      <div class="features-container">
        <div class="features-header">
          <h2>Efficient Payment Gateway Features</h2>
          <p>
            Experience the power of our cutting-edge payment gateway features
          </p>
        </div>

        <div class="feature">
          {/* <div class="icon-box">🔒</div> */}
          <img
            className="feature-img"
            src={require("../images/secure.png")}
            alt="secure transactions"
          />
          <h2>Secure Transactions</h2>
          <p>Ensure safe and encrypted payment transactions</p>
        </div>
        <div class="feature">
          {/* <div class="icon-box">🔄</div> */}
          <img
            className="feature-img"
            src={require("../images/realtime.png")}
            alt="secure transactions"
          />
          <h2>Real-Time Payment Processing</h2>
          <p>Instant processing of payments for seamless transactions</p>
        </div>
        <div class="feature">
          {/* <div class="icon-box">💳</div> */}
          <img
            className="feature-img"
            src={require("../images/multi.png")}
            alt="secure transactions"
          />
          <h2>Multiple Payment Options</h2>
          <p>Accept various payment methods to cater to all customers</p>
        </div>
        <div class="feature">
          {/* <div class="icon-box">🛡️</div> */}
          <img
            className="feature-img"
            src={require("../images/froud.png")}
            alt="secure transactions"
          />
          <h2>Fraud Protection</h2>
          <p>
            Advanced fraud detection mechanisms to safeguard against fraudulent
            activities
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
