import React from "react";
import "./PanelProm.css";
import { MdDashboard } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { IoServerSharp } from "react-icons/io5";

import { FaInfinity } from "react-icons/fa";

const PanelProm = () => {
  return (
    <section className="panel-prom-section">
      <img
        src={require("../images/panel-bg.png")}
        alt="Panel Background Image"
        className="panel-bg-image"
      />

      <div className="panel-content">
        <h3 className="credit-header">Fast & Easy User Panel</h3>
        <img
          src={require("../images/panel.png")}
          alt="Panel Image"
          className="panel-image"
        />
        <div className="panel-feature-container">
          <div className="text-container">
            <MdDashboard className="credit-icon" />
            <h4 className="credit-feature feature-0">
              User-Friendly Dashboard Interface
            </h4>
          </div>
          <div className="text-container">
            <RiSecurePaymentFill className="credit-icon" />
            <h4 className="credit-feature feature-0">
              Fast and Secure Infrastructure
            </h4>
          </div>
          <div className="text-container">
            <IoServerSharp className="credit-icon" />
            <h4 className="credit-feature feature-0">
              High Client Hosting Capacity
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PanelProm;
