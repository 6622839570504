import React from "react";
import "./Faq.css";

const Faq = () => {
  return (
    <section id="faq" className="faq">
      <div className="faq-container">
        <div class="faq-container-left">
          <h3>FAQ</h3>
          <h4>Common questions</h4>
          <p>Here are some of the most common questions that we get.</p>
        </div>
        <div class="faq-container-right">
          <div class="faq-item">
            <h4>What payment methods do you accept?</h4>
            <p>
              We accept all major credit and debit cards, as well as popular
              digital payment methods.
            </p>
          </div>

          <div class="faq-item">
            <h4>Is my payment information secure?</h4>
            <p>
              Yes, we use industry-standard encryption and security protocols to
              ensure that your payment information is safe and secure.
            </p>
          </div>

          <div class="faq-item">
            <h4>
              Are there any additional fees for using your payment gateway?
            </h4>
            <p>
              We strive to maintain complete transparency and competitiveness
              with our fees. Our service is free of charge, and we do not apply
              any commission fees—there are absolutely no hidden costs.
            </p>
          </div>

          <div class="faq-item">
            <h4>How quickly are payments processed?</h4>
            <p>
              Payments are processed in real-time, ensuring that your
              transactions are completed swiftly and efficiently.
            </p>
          </div>

          <div class="faq-item">
            <h4>What should I do if I encounter an issue with a payment?</h4>
            <p>
              If you encounter any issues with a payment, please contact our
              customer support team immediately for assistance.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
