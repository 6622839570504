import React from "react";
import "./FeaturesExtended.css";

const FeaturesExtended = () => {
  return (
    <section id="features-extended" className="features-extended">
      <div class="features-image">
        <img
          src={require("../images/api.png")}
          alt="API Integration"
          className="feature-ex-img"
        />
      </div>
      <div class="features-ex-header">
        <h2>Fast & Easy API Integration</h2>
        <h2>Seamlessly Integrate in Minutes</h2>
        <p>
          Our APIs are meticulously designed for utmost simplicity and
          flexibility, ensuring that you can effortlessly integrate them into
          your systems. This streamlined integration process allows you to
          quickly manage your investment and withdrawal information, setting the
          stage for rapid transaction execution. With user-centric design at its
          core, our APIs provide a hassle-free experience, allowing you to focus
          on what matters most—your financial operations. Benefit from our
          robust and reliable solutions that guarantee swift and seamless
          transactions every time. Embrace the ease of integration and
          operational efficiency with our advanced technology, designed to keep
          you one step ahead in a dynamic financial landscape.
        </p>
      </div>
    </section>
  );
};

export default FeaturesExtended;
