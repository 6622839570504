import React from "react";
import "./Header.css";
import NavBar from "../components/NavBar";

const Header = ({ active }) => {
  return (
    <header id="home">
      <NavBar active={active} />
      <div className="header">
        <h1>Secure Payment Gateway Solutions</h1>
        <h2>
          Simplify your online transactions with our reliable payment gateway
        </h2>
        <button class="discover-btn">Discover</button>
      </div>
    </header>
  );
};

export default Header;
