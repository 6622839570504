import React from "react";
import "./Credit.css";

import { BiSolidCategory } from "react-icons/bi";
import { FaInfinity } from "react-icons/fa";
import { MdOutlineMultipleStop } from "react-icons/md";
import { IoSpeedometer } from "react-icons/io5";

const Credit = () => {
  return (
    <section className="credit-section">
      <img
        src={require("../images/cc-bg.png")}
        alt="Credit Card Background Image"
        className="credit-bg-image"
      />
      <img
        src={require("../images/gold.png")}
        alt="Gold Coin Image"
        className="gold-image"
      />
      <div className="credit-content">
        <h3 className="credit-header">
          Efficient Digital Payments with EasyProPay
        </h3>
        <img
          className="credit-image"
          src={require("../images/cc.png")}
          alt="Credit Card Image"
        />
        <div className="credit-feature-container">
          <div className="text-container">
            <FaInfinity className="credit-icon" />
            <h4 className="credit-feature feature-0">
              Unlimited Payment Transactions
            </h4>
          </div>
          <div className="text-container">
            <IoSpeedometer className="credit-icon" />
            <h4 className="credit-feature feature-2">
              Ultra-Fast Transaction Processing
            </h4>
          </div>
          <div className="text-container">
            <BiSolidCategory className="credit-icon" />
            <h4 className="credit-feature feature-1">
              Supports All Major Card Types
            </h4>
          </div>

          <div className="text-container">
            <MdOutlineMultipleStop className="credit-icon" />
            <h4 className="credit-feature feature-2">
              Rapid Multi-Transaction Capability
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Credit;
