import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div class="contact-container">
        <div class="contact-header">
          <h2>Contact Us</h2>
          <p>Get in touch with us today</p>
        </div>

        <div class="contact-form">
          <form>
            <div
              class="form-group
                "
            >
              <label for="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div
              class="form-group
                "
            >
              <label for="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div
              class="form-group
                "
            >
              <label for="message">Message</label>
              <textarea id="message" name="message" required></textarea>
            </div>
          </form>
        </div>
        <button type="submit" className="button">
          Send Message
        </button>
      </div>
    </section>
  );
};

export default Contact;
