import React from "react";
import "./Footer.css"; // Make sure the CSS file is properly imported

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="navigation-brand footer-brand">
          <img
            src={require("../images/easypropaylogo.png")}
            alt="easypropay logo"
            className="navigation-brand-logo"
            style={{ marginRight: "1.5rem" }}
          />
          <div className="navigation-title footer-title">EASYPROPAY</div>
        </div>
        <p>A SECURE PAYMENT GATEWAY.</p>
        <p>© 2024 EASYPROPAY. All rights reserved.</p>
      </div>
      <ul className="footer-links">
        <li>
          <a href="/privacy">Privacy Policy</a>
        </li>
        <li>
          <a href="/terms">Terms of Service</a>
        </li>
        <li>
          <a href="/support">Support</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
