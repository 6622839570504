import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Header from "./views/Header";
import Features from "./views/Features";
import FeaturesExtended from "./views/FeaturesExtended";
import Faq from "./views/Faq";
import Footer from "./views/Footer";
import Contact from "./views/Contact";
import Credit from "./views/Credit";
import PanelProm from "./views/PanelProm";

const App = () => {
  const [navActive, setNavActive] = useState(false);
  const featuresRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setNavActive(entry.isIntersecting);
      },
      {
        rootMargin: "-50px 0px 0px 0px",
        threshold: 1,
      }
    );

    if (featuresRef.current) {
      observer.observe(featuresRef.current);
    }

    return () => {
      if (featuresRef.current) {
        observer.unobserve(featuresRef.current);
      }
    };
  }, []);

  return (
    <div className="main-container">
      <Header active={navActive} />
      <div ref={featuresRef}>
        <Features />
      </div>
      <Credit />
      <FeaturesExtended />
      <PanelProm />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
