import React from "react";
import "./NavBar.css";
import { IoMenu } from "react-icons/io5";

const NavBar = ({ active }) => {
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div
      className="navigation-bar"
      style={{
        background: active
          ? "transparent"
          : "linear-gradient(to right, rgba(106, 17, 203, 0.8) 0%, rgba(37, 117, 252, 0.8) 100%)",
      }}
    >
      <div className="navigation-brand">
        <img
          src={require("../images/easypropaylogo.png")}
          alt="easypropay logo"
          className="navigation-brand-logo"
        />
        <div className="navigation-title">EASYPROPAY</div>
      </div>

      <div className="navigation-links">
        <a href="#home" onClick={() => scrollToElement("home")}>
          Home
        </a>
        <a href="#features" onClick={() => scrollToElement("features")}>
          Features
        </a>
        <div className="happy-customer"></div>
        <a href="#faq" onClick={() => scrollToElement("faq")}>
          FAQ
        </a>
        <a href="#contact" onClick={() => scrollToElement("contact")}>
          Contact
        </a>
      </div>
      {/* MENU SEMBOL */}
      <div className="menu-icon">
        <IoMenu size={50} />
      </div>
      <div className="navigation-button">Login</div>
    </div>
  );
};

export default NavBar;
